import React from 'react'
import { Box, BoxProps } from '@thirstycamel/ui'

export interface DividerProps extends BoxProps {}

export const Divider = ({ ...restProps }: DividerProps) => {
  return <Box h="2px" bg="gray.200" w="100%" {...restProps} />
}

export default Divider
