import React from 'react'
import { Flex, FlexProps, Logo, Box, LinkButton, Link } from '@thirstycamel/ui'
import OrderNotificationPopout from '../OrderNotificationList/OrderNotificationList'
import { useUser } from '../../hooks/useUser'

export interface MainHeadNavProps extends FlexProps {
  backHref?: string
  backHrefAs?: string
  backIcon?: string
  backLabel?: string
}

export const MainHeadNav = ({
  backHref,
  backHrefAs,
  backIcon = 'chevron-left',
  backLabel = 'Back',
  ...restProps
}: MainHeadNavProps) => {
  const { isStoreUser } = useUser()
  return (
    <Flex
      justify="center"
      h="86px"
      bg="gray.50"
      borderBottom="2px"
      borderColor="gray.200"
      px={4}
      {...restProps}
    >
      <Flex justify="space-between" align="center" w="100%" maxWidth="1000px">
        <Box flex="1 0 0">
          {backHref && (
            <LinkButton
              href={backHref}
              hrefAs={backHrefAs}
              variantColor="pink"
              variant="ghost"
              size="lg"
              leftIcon={backIcon}
              px={2}
            >
              {backLabel}
            </LinkButton>
          )}
        </Box>

        <Flex flex="1 0 0" justify="center">
          <Link href="/">
            <Logo h="48px" />
          </Link>
        </Flex>
        <Flex flex="1 0 0" justify="flex-end">
          {isStoreUser && <OrderNotificationPopout />}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default MainHeadNav
