import React from 'react'
import { LinkButton, LinkButtonProps, ButtonProps, Button } from '@thirstycamel/ui'
import { useUser } from '../../hooks/useUser'

export interface SidebarButtonProps extends Partial<LinkButtonProps> {}

export const SidebarButton = ({ href, children, ...restProps }: SidebarButtonProps) => {
  const _props: ButtonProps = {
    variantColor: 'blue',
    isRound: true,
    rightIcon: 'chevron-right',
    justifyContent: 'space-between',
    px: 4,
    fontSize: 'xs',
    flexShrink: 0,
  }

  if (!href) {
    return (
      <Button {..._props} {...restProps}>
        {children}
      </Button>
    )
  }

  return (
    <LinkButton href={href} {..._props} {...restProps}>
      {children}
    </LinkButton>
  )
}

export default SidebarButton
