import React from 'react'
import { Flex, FlexProps, CloseButton } from '@thirstycamel/ui'

export interface BannerProps extends FlexProps {
  onClose?: () => void
  isClosable?: boolean
}

export const Banner = ({ children, onClose, isClosable, ...restProps }: BannerProps) => {
  return (
    <Flex
      w="100%"
      align="center"
      justify="center"
      bg="pink.500"
      color="white"
      fontWeight="bold"
      {...restProps}
    >
      {children}

      {isClosable && (
        <CloseButton
          color="white"
          position="absolute"
          top="50%"
          transform="translateY(-50%)"
          right={5}
          onClick={onClose}
        />
      )}
    </Flex>
  )
}

export default Banner
