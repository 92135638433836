import React, { useState } from 'react'
import Head from 'next/head'
import { Global } from '@emotion/core'
import { Box, BoxProps, ContainerProps, Text, Stack, Flex, Heading } from '@thirstycamel/ui'

import MainHeadNav, { MainHeadNavProps } from '../../components/MainHeadNav'
import Sidebar from '../../components/Sidebar'
import SidebarButton from '../../components/SidebarButton'
import Divider from '../../components/Divider'
import Banner from '../../components/Banner'
import LoginForm from '../../components/LoginForm'
import { useActions } from '../../store/hooks'
import { useRouter } from 'next/router'
import { useUser } from '../../hooks/useUser'
import ForgotPasswordForm from '../../components/ForgotPasswordForm'

import { useInterval } from 'react-use'
import cookie from 'cookie'
import { sendMessage } from '../../store/model/auth'
import { AdminLevel } from '@ts/core/src/modules/auth/auth.object'
import { UserPermission } from '@ts/core/src/modules/user/user.enums'

interface SidebarItem {
  label: string
  href: string
  adminLevels?: AdminLevel[]
  heading?: never
  items?: never
  permissions?: UserPermission[]
  ecommerceOnly?: boolean
}

interface SidebarGroup {
  heading: string
  items: SidebarItem[]
  label?: never
  href?: never
  adminLevels?: AdminLevel[]
  ecommerceOnly?: boolean
}

type SidebarItems = (SidebarGroup | SidebarItem)[]

const sidebarItems: { store: SidebarItems; admin: SidebarItems } = {
  store: [
    { label: 'Orders', href: '/orders', ecommerceOnly: true },
    // { label: 'Products', href: '/products', ecommerceOnly: true },
    { label: 'Products', href: '/store-products ' },
    { label: 'My Store', href: '/stores/me' },
    { label: 'Reporting', href: '/reporting' },
  ],
  admin: [
    {
      heading: 'Ecomm',
      adminLevels: [AdminLevel.FULL],
      items: [
        {
          label: 'Orders',
          href: '/orders',
          adminLevels: [AdminLevel.FULL],
          permissions: [UserPermission.ORDERS],
        },
        {
          label: 'Fraud Center',
          href: '/fraud',
          adminLevels: [AdminLevel.FULL],
          permissions: [UserPermission.FRAUD_CENTER],
        },
        {
          label: 'Promotions',
          href: '/promotions',
          permissions: [UserPermission.PROMOTIONS],
          adminLevels: [AdminLevel.FULL, AdminLevel.PRODUCT_PRICING_ONLY],
        },
        {
          label: 'Payouts',
          href: '/payouts',
          adminLevels: [AdminLevel.FULL],
          permissions: [UserPermission.PAYOUTS],
        },
      ],
    },
    {
      heading: 'Products',
      items: [
        {
          label: 'Products',
          href: '/product-groups',
          adminLevels: [AdminLevel.FULL, AdminLevel.PRODUCT_PRICING_ONLY],
          permissions: [UserPermission.PRODUCTS],
        },
        {
          label: 'Bundles',
          href: '/bundles',
          adminLevels: [AdminLevel.FULL, AdminLevel.PRODUCT_PRICING_ONLY],
          permissions: [UserPermission.BUNDLES],
        },
        {
          label: 'Product Lists',
          href: '/product-lists',
          adminLevels: [AdminLevel.FULL],
          permissions: [UserPermission.PRODUCT_LISTS],
        },
        {
          label: 'Reviews',
          href: '/reviews',
          adminLevels: [AdminLevel.FULL],
          permissions: [UserPermission.REVIEWS],
        },
        {
          label: 'Brands',
          href: '/brands',
          adminLevels: [AdminLevel.FULL, AdminLevel.PRODUCT_PRICING_ONLY],
          permissions: [UserPermission.BRANDS],
        },
        {
          label: 'Categories',
          href: '/category-groups',
          adminLevels: [AdminLevel.FULL, AdminLevel.PRODUCT_PRICING_ONLY],
          permissions: [UserPermission.CATEGORIES],
        },
        {
          label: 'Imagery',
          href: '/media',
          adminLevels: [AdminLevel.FULL, AdminLevel.PRODUCT_PRICING_ONLY],
          permissions: [UserPermission.IMAGERY],
        },
        {
          label: 'Availability',
          href: '/availability',
          adminLevels: [AdminLevel.FULL, AdminLevel.PRODUCT_PRICING_ONLY],
          permissions: [UserPermission.SPECIALS],
        },
      ],
    },
    {
      heading: 'Pricing',
      items: [
        {
          label: 'MSP Pricing',
          href: '/cpi-pricing',
          adminLevels: [AdminLevel.FULL, AdminLevel.PRODUCT_PRICING_ONLY],
          permissions: [UserPermission.MSP_PRICING],
        },
        {
          label: 'Specials',
          href: '/specials',
          adminLevels: [AdminLevel.FULL, AdminLevel.PRODUCT_PRICING_ONLY],
          permissions: [UserPermission.SPECIALS],
        },
        {
          label: 'Everyday Value',
          href: '/everyday-value',
          adminLevels: [AdminLevel.FULL, AdminLevel.PRODUCT_PRICING_ONLY],
          permissions: [UserPermission.EVERYDAY_VALUE],
        },
      ],
    },
    {
      heading: 'Hump Club',
      adminLevels: [AdminLevel.FULL],
      items: [
        {
          label: 'Humpers',
          href: '/humpers',
          adminLevels: [AdminLevel.FULL],
          permissions: [UserPermission.HUMPERS],
        },
        {
          label: 'Offers',
          href: '/offers',
          adminLevels: [AdminLevel.FULL],
          permissions: [UserPermission.OFFERS],
        },
        {
          label: 'Push Notifications',
          href: '/push-notifications',
          adminLevels: [AdminLevel.FULL],
          permissions: [UserPermission.PUSH_NOTIFICATIONS],
        },
      ],
    },
    {
      heading: 'Stores',
      items: [
        {
          label: 'Stores',
          href: '/stores',
          adminLevels: [
            AdminLevel.FULL,
            AdminLevel.PRODUCT_PRICING_ONLY,
            AdminLevel.STORE_ACCESS_ONLY,
          ],
          permissions: [UserPermission.STORES],
        },
        {
          label: 'Users',
          href: '/users',
          adminLevels: [AdminLevel.FULL],
          permissions: [UserPermission.USERS],
        },
        {
          label: 'Invites',
          href: '/invites',
          adminLevels: [AdminLevel.FULL],
          permissions: [UserPermission.INVITES],
        },
      ],
    },
    {
      heading: 'Other',
      adminLevels: [AdminLevel.FULL],
      items: [
        {
          label: 'Competitions',
          href: '/competitions',
          adminLevels: [AdminLevel.FULL],
          permissions: [UserPermission.COMPETITIONS],
        },
        {
          label: 'Loyalty Games',
          href: '/games',
          adminLevels: [AdminLevel.FULL],
          permissions: [UserPermission.GAMES],
        },
        {
          label: 'Reporting',
          href: '/reporting',
          adminLevels: [AdminLevel.FULL],
          permissions: [UserPermission.REPORTING],
        },
      ],
    },
  ],
}

export interface LayoutMainProps extends BoxProps {
  title?: string
  ogTitle?: string
  description?: string
  ogDescription?: string
  robots?: string
  ogImage?: string
  ogType?: string
  hideNav?: boolean
  hideSidebar?: boolean
  hideMenuButton?: boolean
  containerSize?: ContainerProps['size']
  heading?: string
  subHeading?: string
  sidebar?: React.ReactNode
  backHref?: MainHeadNavProps['backHref']
  backHrefAs?: MainHeadNavProps['backHrefAs']
  bypassAuth?: boolean
  loading?: boolean
  fullWidth?: boolean
}

const handleInterval = () => {
  const cookies = document.cookie

  const { portal_token } = cookie.parse(cookies)

  if (portal_token) {
    sendMessage('PORTAL_TOKEN', portal_token)
  }
}

export function LayoutMain({
  title,
  children,
  hideNav,
  hideSidebar,
  hideMenuButton,
  containerSize = 'lg',
  heading,
  subHeading,
  sidebar,
  backHref,
  backHrefAs,
  bypassAuth,
  loading,
  fullWidth = false,
  ...restProps
}: LayoutMainProps) {
  const router = useRouter()

  const { user, isImpersonating, isLoggedIn, isAdmin: isUserAdmin } = useUser()

  const _title = title ? `${title} | Thirsty Camel Portal` : 'Thirsty Camel Portal'
  const _backHref = (router.query.backHref as string) || backHref || '/'
  const _backHrefAs = (router.query.backHrefAs as string) || backHrefAs

  const stopImpersonating = useActions(store => store.auth.stopImpersonating)

  const [reset, setReset] = useState(false)

  const handleCancelImpersonate = () => {
    stopImpersonating()
    router.push('/')
  }

  React.useEffect(() => {
    handleInterval()
  }, [])

  // run every minute
  useInterval(handleInterval, 1000 * 20)

  const getAllowedItems = (items: SidebarItem[]) => {
    return items
      ? items.filter(item => {
        if (!item.adminLevels && !item.permissions) {
          return true
        }

        let passedAdminLevelCheck = true
        let passedPermissionLevelCheck = true

        if (item.adminLevels.length) {
          passedAdminLevelCheck = item.adminLevels.includes(user.adminLevel)
        }

        if (item.permissions.length) {
          passedPermissionLevelCheck = item.permissions.every(perm =>
            user.permissions?.includes(perm),
          )
        }

        return passedPermissionLevelCheck && passedAdminLevelCheck
      })
      : []
  }

  return (
    <>
      <Head>
        <title>{_title}</title>
      </Head>

      <Global styles={{ overflowX: 'hidden' }} />

      <Box minHeight="100vh" bg="#f8f8f8" {...restProps}>
        {isImpersonating && (
          <Banner
            h="48px"
            position="fixed"
            left={0}
            right={0}
            zIndex={10}
            isClosable
            onClose={handleCancelImpersonate}
          >
            <Text>You are impersonating {user?.store?.name}</Text>
          </Banner>
        )}

        <MainHeadNav
          position="fixed"
          top={isImpersonating ? '48px' : 0}
          right={0}
          left={0}
          zIndex={10}
          backHref={isLoggedIn && !hideMenuButton && _backHref}
          backHrefAs={_backHrefAs}
        />

        <Box
          borderLeft="1px"
          borderRight="1px"
          borderColor="gray.200"
          maxWidth={!fullWidth ? '1000px' : '100%'}
          mx="auto"
          h="100%"
          bg="white"
        >
          {!isLoggedIn && !bypassAuth ? (
            <Box h="100%" minHeight="100vh" mt="86px">
              <Stack spacing={5} py={6} px={5}>
                <Heading>{!reset ? 'Login' : 'Reset Password'}</Heading>
                <Divider />
                {!reset ? (
                  <LoginForm onReset={() => setReset(true)} />
                ) : (
                  <ForgotPasswordForm onCancel={() => setReset(false)} />
                )}
              </Stack>
            </Box>
          ) : (
            <Flex pt={isImpersonating ? 'calc(86px + 48px)' : '86px'} minHeight="100vh" h="100%">
              {!hideSidebar &&
                (sidebar || (
                  <Sidebar maxWidth="25vw" overflowY="auto" flexShrink={0} paddingBottom="5rem">
                    {sidebarItems[isUserAdmin ? 'admin' : 'store']
                      .filter(item => {
                        if (!user.store) {
                          return true
                        }

                        if (item.ecommerceOnly) {
                          return user.store.isDelivery || user.store.isPickup
                        } else {
                          return true
                        }
                      })
                      .filter(
                        item =>
                          (!item.adminLevels?.length ||
                            item.adminLevels.includes(user.adminLevel)) &&
                          (getAllowedItems(item.items).length > 0 || !isUserAdmin),
                      )
                      .map(({ label, href, heading, items }) =>
                        heading ? (
                          [
                            <Heading
                              fontSize="xs"
                              textTransform="uppercase"
                              color="blackAlpha.900"
                              ml={4}
                            >
                              {heading}
                            </Heading>,
                            getAllowedItems(items).map(item => (
                              <SidebarButton href={item.href} key={item.href}>
                                {item.label}
                              </SidebarButton>
                            )),
                            <Divider flexShrink={0} />,
                          ]
                        ) : (
                          <SidebarButton href={href} key={href}>
                            {label}
                          </SidebarButton>
                        ),
                      )}
                    {!isImpersonating && <Divider />}
                    {!isImpersonating && (
                      <SidebarButton href="/logout" variant="outline" variantColor="pink">
                        Logout
                      </SidebarButton>
                    )}
                  </Sidebar>
                ))}

              <Box w="100%">{!loading && children}</Box>
            </Flex>
          )}
        </Box>
      </Box>
    </>
  )
}

export default LayoutMain
