import React from 'react'
import { Stack, StackProps } from '@thirstycamel/ui'

export interface SidebarProps extends StackProps {}

export const Sidebar = ({ children, ...restProps }: SidebarProps) => {
  return (
    <Stack spacing={3} py={5} px={3} borderRight="2px" borderColor="gray.200" {...restProps}>
      {children}
    </Stack>
  )
}

export default Sidebar
