import React from 'react'
import {
  Box,
  BoxProps,
  FormProvider,
  Stack,
  TextInput,
  Button,
  useForm,
  Alert,
  useToast,
  useBackendMutation,
} from '@thirstycamel/ui'
import type { UserLoginDto } from '@ts/core/src/modules/auth/dto/UserLoginDto'

import { useActions, useStore } from '../../store/hooks'
import Input from '../Input'
import Divider from '../Divider'
import FormWrapper from '../FormWrapper'
import FormError from '../FormError'
import type { ForgotPasswordDto } from '@ts/core/src/modules/auth/dto/ForgotPasswordDto'

interface FormData extends UserLoginDto {}

export interface LoginFormProps extends BoxProps {
  onReset: () => void
}

export const LoginForm = ({ onReset, ...restProps }: LoginFormProps) => {
  const toast = useToast()
  const form = useForm<FormData>()

  const login = useActions(store => store.auth.login)
  const { isFetching, error } = useStore(store => store.auth)

  const handleSubmit = form.handleSubmit(async values => {
    const user = await login(values)
  })

  return (
    <FormWrapper form={form} onSubmit={handleSubmit} {...restProps}>
      <Stack spacing={5}>
        <Input
          as={TextInput}
          type="email"
          name="email"
          label="Email address"
          placeholder="e.g. john@example.com"
          isRequired
          rules={{ required: 'Please enter your email address.' }}
        />

        <Input
          as={TextInput}
          type="password"
          name="password"
          label="Password"
          placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
          isRequired
          rules={{
            required: 'Please enter your password.',
            minLength: { value: 6, message: 'Password must be at least 6 characters long.' },
          }}
        />

        <Divider />

        <FormError
          errors={error}
          overrides={{
            404: 'Could not find a matching record for that email & password.',
          }}
        />

        <Stack isInline spacing={3}>
          <Button
            type="submit"
            variantColor="pink"
            isRound
            rightIcon="chevron-right"
            isLoading={isFetching}
          >
            Login
          </Button>
          <Button
            type="button"
            variantColor="pink"
            variant="ghost"
            isRound
            isDisabled={isFetching}
            onClick={onReset}
          >
            Forgot Password?
          </Button>
        </Stack>
      </Stack>
    </FormWrapper>
  )
}

export default LoginForm
