import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request'
import {
  Badge,
  Box,
  Button,
  Card,
  Flex,
  Icon,
  Image,
  Link,
  Popout,
  PopoutContent,
  PopoutTrigger,
  PseudoBox,
  Stack,
  Text,
  useBackendMutation,
  useBackendQuery,
} from '@thirstycamel/ui'
import { CrudPaginated } from '@thirstycamel/ui/lib/types/pagination'
import type OrderEntity from '@ts/core/src/modules/order/order.entity'
import { BulkMarkAcknowledged } from '@ts/core/src/modules/order/order.object'
import dayjs from 'dayjs'
import React, { FC, useCallback, useEffect, useState } from 'react'
import useSound from 'use-sound'
import { useAuthHeaders } from '../../hooks/useAuthHeaders'
import imageProxy from '../../utils/imageProxy'

const cashRegister = require('./purchase.mp3')
const reminder = require('./doorbell.mp3')

const OrderNotificationListItem: FC<{ order: OrderEntity }> = ({ order }) => {
  const imageUrl = order.lineItems?.[0]?.product?.media?.[0]?.filename
    ? imageProxy(order.lineItems[0]?.product?.media?.[0]?.filename, { height: 64 })
    : null

  return (
    <Link href="/orders/[id]" hrefAs={`/orders/${order.id}`}>
      <PseudoBox
        p={1}
        m={0}
        rounded={0}
        borderTop={0}
        flexDir="row"
        as={Card}
        _hover={{ background: 'grey.500' }}
      >
        <Flex>
          {imageUrl && (
            <Box position="relative">
              <Image src={imageUrl} />
              {order.lineItems.length > 1 && (
                <Badge position="absolute" bottom={0} right={0} variantColor="green">
                  +{order.lineItems.length - 1}
                </Badge>
              )}
            </Box>
          )}

          <Flex flexDir="column">
            <Text color="pink.500" fontWeight="bold">
              {order.shortId}
            </Text>
            <Text fontWeight="bold">
              {order.firstName} {order.lastName}
            </Text>
            <Text color="grey" fontSize="sm">
              {dayjs(order.createdAt).format('DD/MM/YYYY hh:mm a')}
            </Text>
          </Flex>
        </Flex>
      </PseudoBox>
    </Link>
  )
}

const OrderNotificationEmpty = () => {
  return (
    <Flex height="200px" alignItems="center" justifyContent="center">
      <Text color="gray.500" textAlign="center">
        No New Orders
      </Text>
    </Flex>
  )
}

const FIFTEEN_SECONDS = 5000

const OrderNotificationPopout = () => {
  const request = RequestQueryBuilder.create({})

  request
    .setFilter({ field: 'acknowledgedAt', operator: CondOperator.IS_NULL })
    .setFilter({ field: 'cancelledAt', operator: CondOperator.IS_NULL })
    .setFilter({ field: 'deliveredAt', operator: CondOperator.IS_NULL })
    .setFilter({ field: 'completedAt', operator: CondOperator.IS_NULL })
    .setFilter({ field: 'type', operator: 'eq', value: 'TAH' })
    .sortBy({ field: 'createdAt', order: 'DESC' })

  request.query()

  const [chaching] = useSound(cashRegister)
  const [reminderTone] = useSound(reminder)

  let { data: orders, refetch } = useBackendQuery<CrudPaginated<OrderEntity>>(
    [`orders?${request.queryString}`, useAuthHeaders()],
    {
      refetchInterval: FIFTEEN_SECONDS,
      staleTime: 10000,
      onSuccess: newData => {
        let hasNew = false
        const initial = !orders

        if (initial) {
          if (newData.count) {
            reminderTone()
          }
          return
        }

        if (orders.count < newData.count) hasNew = true

        if (!hasNew) {
          const oldOrders = newData?.data?.map(({ id }) => id)
          const newOrders = newData?.data?.filter(order => !oldOrders.includes(order.id))

          if (newOrders.length) hasNew = true
        }

        if (hasNew) {
          chaching()
        } else if (newData.count) {
          reminderTone()
        }
      },
    },
  )

  // can't reach order key from here, so have to wait for potential page load to acknowledge an order
  useEffect(() => {
    const timeout = setTimeout(() => {
      refetch()
    }, 1500)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const [bulkRead] = useBackendMutation<void, BulkMarkAcknowledged>(
    ['/orders/read-bulk', useAuthHeaders()],
    { onSettled: () => refetch() },
  )

  const markBulkRead = () => bulkRead({ ids: orders.data.map(({ id }) => id) })

  const hasOrders = !!orders?.count
  return (
    <Popout placement="top-start" strategy="fixed">
      {({ isOpen }) => {
        return (
          <Box>
            <PopoutTrigger>
              <Button variant="unstyled">
                <Stack direction="row" spacing={2}>
                  <Text fontWeight="bold" color={hasOrders ? 'black' : 'grey'}>
                    NEW ASAP ORDERS
                  </Text>

                  <Badge bottom={0} right={0}>
                    {orders?.count}
                  </Badge>

                  <Icon name={isOpen ? 'chevron-down' : 'chevron-up'} />
                </Stack>
              </Button>
            </PopoutTrigger>
            <PopoutContent p={0} w="300px" bg="white">
              <Box>
                {!!orders?.count ? (
                  orders?.data?.map(order => <OrderNotificationListItem order={order} />)
                ) : (
                  <OrderNotificationEmpty />
                )}

                {!!orders?.count && (
                  <Flex direction="row-reverse" bg="gray.50">
                    <Button variant="ghost" variantColor="pink" onClick={markBulkRead}>
                      MARK ALL AS SEEN
                    </Button>
                  </Flex>
                )}
              </Box>
            </PopoutContent>
          </Box>
        )
      }}
    </Popout>
  )
}

export default OrderNotificationPopout
