import {
  BoxProps,
  Button,
  Stack,
  Text,
  TextInput,
  useBackendMutation,
  useForm,
} from '@thirstycamel/ui'
import type { ForgotPasswordDto } from '@ts/core/src/modules/auth/dto/ForgotPasswordDto'
import React, { useState } from 'react'
import Divider from '../Divider'
import FormError from '../FormError'
import FormWrapper from '../FormWrapper'
import Input from '../Input'

interface FormData extends ForgotPasswordDto {}

export interface ForgotPasswordFormProps extends BoxProps {
  onCancel: () => void
}

export const ForgotPasswordForm = ({ onCancel, ...restProps }: ForgotPasswordFormProps) => {
  const form = useForm<FormData>()

  const [reset, { isLoading: isFetching, error }] = useBackendMutation<void, ForgotPasswordDto>([
    '/auth/reset-password',
    { method: 'POST' },
  ])

  const [finished, setFinished] = useState(false)

  const handleSubmit = form.handleSubmit(async values => {
    await reset(values)

    setFinished(true)
  })

  if (finished) {
    return (
      <FormWrapper form={form} onSubmit={handleSubmit} {...restProps}>
        <Stack spacing={5}>
          <Text>Please check your inbox for instructions on how to reset your password.</Text>
        </Stack>
      </FormWrapper>
    )
  }

  return (
    <FormWrapper form={form} onSubmit={handleSubmit} {...restProps}>
      <Stack spacing={5}>
        <Input
          as={TextInput}
          type="email"
          name="email"
          label="Email address"
          placeholder="e.g. john@example.com"
          isRequired
          rules={{ required: 'Please enter your email address.' }}
        />

        <Divider />

        <FormError errors={error} />

        <Stack isInline spacing={3}>
          <Button
            type="submit"
            variantColor="pink"
            isRound
            rightIcon="chevron-right"
            isLoading={isFetching}
          >
            Reset Password
          </Button>
          <Button
            type="button"
            variantColor="pink"
            variant="ghost"
            isRound
            isLoading={isFetching}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </FormWrapper>
  )
}

export default ForgotPasswordForm
