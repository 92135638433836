import { AxiosError } from 'axios'
import { isAxiosError } from './typeguards'

export const getNiceErrorMessage = (
  error: AxiosError | Error,
  overrides?: { [key: number]: string },
) => {
  if (!error) return error

  console.log(error)

  if (isAxiosError(error)) {
    if (overrides && overrides[error.response?.status]) {
      return overrides[error.response.status]
    }

    if (error.message === 'Network Error') {
      return 'Unable to connect to the API. Please check your internet connection and try again, or contact support.'
    }

    if (error.response?.data?.message) {
      return error.response.data.message
    }

    return error.response?.data?.error === 'Bad Request'
      ? 'Whoops! Something went wrong.'
      : error.response?.data?.error || 'Whoops! Something went wrong.'
  }

  return error.message || error
}
