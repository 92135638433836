import React from 'react'
import { FormProvider, Box, BoxProps, UseFormMethods } from '@thirstycamel/ui'

export interface FormWrapperProps extends BoxProps {
  form: UseFormMethods<any>
}

export const FormWrapper = ({ form, ...restProps }: FormWrapperProps) => {
  return (
    <FormProvider {...form}>
      <Box as="form" bg="gray.50" p={5} border="1px" borderColor="gray.200" {...restProps} />
    </FormProvider>
  )
}

export default FormWrapper
